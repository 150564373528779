const styles = {
  svg: {
    display: "inline-block",
    verticalAlign: "middle",
  },
};

const PrevArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27 44"
      height="20"
      style={styles.svg}
    >
      <path
        d="M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z"
        fill="#000"
      />
    </svg>
  );
};

const NextArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27 44"
      height="20"
      style={styles.svg}
    >
      <path
        d="M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z"
        fill="#000"
      />
    </svg>
  );
};

const Whatsapp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28pt"
      height="28pt"
      viewBox="0 0 48 48"
      version="1.1"
    >
      <g id="surface528609">
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: "1",
          }}
          d="M 4.867188 43.304688 L 7.5625 33.46875 C 5.898438 30.589844 5.027344 27.324219 5.027344 23.980469 C 5.03125 13.515625 13.546875 5 24.015625 5 C 29.09375 5.003906 33.859375 6.980469 37.445312 10.566406 C 41.027344 14.152344 43.003906 18.921875 43 23.992188 C 42.996094 34.460938 34.476562 42.972656 24.015625 42.972656 C 24.011719 42.972656 24.015625 42.972656 24.015625 42.972656 L 24.007812 42.972656 C 20.828125 42.972656 17.707031 42.175781 14.933594 40.664062 Z M 4.867188 43.304688 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: "1",
          }}
          d="M 4.867188 43.804688 C 4.734375 43.804688 4.609375 43.75 4.511719 43.65625 C 4.386719 43.527344 4.339844 43.34375 4.386719 43.171875 L 7.023438 33.535156 C 5.390625 30.628906 4.527344 27.328125 4.527344 23.980469 C 4.53125 13.238281 13.273438 4.5 24.015625 4.5 C 29.222656 4.503906 34.117188 6.53125 37.796875 10.214844 C 41.476562 13.894531 43.503906 18.789062 43.5 23.992188 C 43.496094 34.734375 34.753906 43.472656 24.015625 43.472656 C 20.824219 43.472656 17.671875 42.6875 14.871094 41.195312 L 4.996094 43.785156 C 4.953125 43.796875 4.910156 43.804688 4.867188 43.804688 Z M 4.867188 43.804688 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(81.176471%,84.705882%,86.27451%)",
            fillOpacity: "1",
          }}
          d="M 24.015625 5 C 29.09375 5.003906 33.859375 6.980469 37.445312 10.566406 C 41.027344 14.152344 43.003906 18.921875 43 23.992188 C 42.996094 34.460938 34.476562 42.972656 24.015625 42.972656 L 24.007812 42.972656 C 20.828125 42.972656 17.707031 42.175781 14.933594 40.664062 L 4.867188 43.304688 L 7.5625 33.46875 C 5.898438 30.589844 5.027344 27.324219 5.027344 23.980469 C 5.03125 13.515625 13.546875 5 24.015625 5 M 24.015625 42.972656 L 24.015625 42.972656 M 24.015625 42.972656 L 24.015625 42.972656 M 24.015625 4 C 12.996094 4 4.03125 12.960938 4.027344 23.980469 C 4.027344 27.347656 4.875 30.664062 6.488281 33.601562 L 3.902344 43.039062 C 3.808594 43.386719 3.90625 43.753906 4.15625 44.007812 C 4.347656 44.199219 4.605469 44.304688 4.867188 44.304688 C 4.953125 44.304688 5.039062 44.292969 5.121094 44.269531 L 14.808594 41.730469 C 17.636719 43.199219 20.808594 43.972656 24.007812 43.976562 C 35.03125 43.976562 43.996094 35.011719 44 23.996094 C 44.003906 18.65625 41.925781 13.636719 38.152344 9.859375 C 34.378906 6.082031 29.355469 4.003906 24.015625 4 Z M 24.015625 4 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(25.098039%,76.470588%,31.764706%)",
            fillOpacity: "1",
          }}
          d="M 35.175781 12.832031 C 32.195312 9.851562 28.234375 8.207031 24.019531 8.207031 C 15.316406 8.207031 8.234375 15.28125 8.230469 23.980469 C 8.230469 26.960938 9.066406 29.863281 10.644531 32.375 L 11.019531 32.972656 L 9.425781 38.792969 L 15.398438 37.226562 L 15.976562 37.570312 C 18.398438 39.007812 21.175781 39.769531 24.007812 39.769531 L 24.015625 39.769531 C 32.710938 39.769531 39.789062 32.691406 39.792969 23.992188 C 39.796875 19.777344 38.15625 15.8125 35.175781 12.832031 Z M 35.175781 12.832031 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "evenodd",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: "1",
          }}
          d="M 19.269531 16.046875 C 18.914062 15.253906 18.539062 15.238281 18.199219 15.226562 C 17.921875 15.214844 17.605469 15.214844 17.289062 15.214844 C 16.976562 15.214844 16.460938 15.332031 16.027344 15.808594 C 15.589844 16.28125 14.363281 17.429688 14.363281 19.765625 C 14.363281 22.097656 16.066406 24.355469 16.300781 24.671875 C 16.539062 24.984375 19.585938 29.929688 24.40625 31.832031 C 28.414062 33.410156 29.230469 33.097656 30.097656 33.019531 C 30.96875 32.9375 32.90625 31.871094 33.300781 30.761719 C 33.695312 29.65625 33.695312 28.707031 33.578125 28.507812 C 33.460938 28.308594 33.144531 28.191406 32.667969 27.953125 C 32.195312 27.714844 29.863281 26.570312 29.425781 26.410156 C 28.992188 26.253906 28.675781 26.175781 28.359375 26.648438 C 28.042969 27.121094 27.132812 28.191406 26.855469 28.507812 C 26.578125 28.824219 26.304688 28.863281 25.828125 28.628906 C 25.355469 28.390625 23.828125 27.890625 22.015625 26.273438 C 20.605469 25.015625 19.652344 23.464844 19.375 22.988281 C 19.097656 22.515625 19.34375 22.257812 19.582031 22.019531 C 19.796875 21.808594 20.058594 21.464844 20.296875 21.1875 C 20.53125 20.910156 20.609375 20.714844 20.769531 20.398438 C 20.925781 20.082031 20.847656 19.804688 20.730469 19.566406 C 20.613281 19.328125 19.691406 16.984375 19.269531 16.046875 Z M 19.269531 16.046875 "
        />
      </g>
    </svg>
  );
};

const Badge = ({ width = "28px", height = "28px" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={width}
      height={height}
    >
      <polygon
        fill="#42a5f5"
        points="29.62,3 33.053,8.308 39.367,8.624 39.686,14.937 44.997,18.367 42.116,23.995 45,29.62 39.692,33.053 39.376,39.367 33.063,39.686 29.633,44.997 24.005,42.116 18.38,45 14.947,39.692 8.633,39.376 8.314,33.063 3.003,29.633 5.884,24.005 3,18.38 8.308,14.947 8.624,8.633 14.937,8.314 18.367,3.003 23.995,5.884"
      />
      <polygon
        fill="#fff"
        points="21.396,31.255 14.899,24.76 17.021,22.639 21.428,27.046 30.996,17.772 33.084,19.926"
      />
    </svg>
  );
};

export { PrevArrow, NextArrow, Whatsapp, Badge };
