import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    es: {
      translation: {
        whereAreYou: "¿Dónde estás?",
        search: "Buscar",
        clear: "Limpiar",
        escortsInLima: "Escorts en Lima",
        english: "Inglés",
        portuguese: "Portugés",
        spanish: "Español",
        advertiseFree: "Anuncia gratis",
        seeMore: "Ver mas",
        endOfList: "Fin de la lista",
        homeh1: "Putas y Kinesiólogas en Perú",
        homeh2:
          "kinesiologas Escorts en Lima Perú y extranjeras, videollamadas de kines A1 putas kines",
        welcomeText:
          "Bienvenidos a Latin Girls, guía de escorts en Lima, Perú. Nuestro objetivo como siempre es garantizar un experiencia al usuario de alta calidad donde todo el contenido esté trabajado al detalle y satisfacer así a los paladares más exquisitos.",
        AR: "Argentino",
        BO: "Boliviana",
        BR: "Brasileña",
        CL: "Chilena",
        CO: "Colombiana",
        CR: "Costarricense",
        CU: "Cubana",
        DO: "Dominicana",
        EC: "Ecuatoriana",
        MX: "Mexicana",
        PA: "Panameña",
        PY: "Paraguaya",
        PE: "Peruana",
        PR: "Puertoriqueña",
        UY: "Uruguaya",
        VE: "Venezolana",
        verified: "Verificada",
        footer: {
          solutions: "Soluciones",
          indepenendent: "Independientes",
          agencies: "Agencias",
          particulars: "Particulares",
          services: "Servicios",
          promotions: "Promociones",
          about: "Acerca de",
          aboutUs: "Nosotros",
          contact: "Contacto",
          tyc: "Términos y Condiciones",
          copy: "© 2023 Latin Girls Inc. Todos los derechos reservados.",
        },
      },
    },
    en: {
      translation: {
        whereAreYou: "Where are you?",
        search: "Search",
        clear: "Clear",
        escortsInLima: "Escorts in Lima",
        english: "English",
        portuguese: "Portuguese",
        spanish: "Spanish",
        advertiseFree: "Advertise for free",
        seeMore: "See more",
        endOfList: "End of list",
        homeh1: "Whores and Kinesiologists in Peru",
        homeh2:
          "kinesiologists Escorts in Lima Peru and foreigners, video calls from kines A1 kines whores",
        welcomeText:
          "Welcome to Latin Girls, escort guide in Lima, Peru. Our goal, as always, is to guarantee a high-quality user experience where all content is worked in detail and thus satisfy the most exquisite palates.",
        AR: "Argentinian",
        BO: "Bolivian",
        BR: "Brazilian",
        CL: "Chilean",
        CO: "Colombian",
        CR: "Costa Rican",
        CU: "Cuban",
        DO: "Dominican",
        EC: "Ecuadorian",
        MX: "Mexican",
        PA: "Panamanian",
        PY: "Paraguayan",
        PE: "Peruvian",
        PR: "Puerto Rican",
        UY: "Uruguayan",
        VE: "Venezuelan",
        verified: "Verified",
        footer: {
          solutions: "Solutions",
          indepenendent: "Independents",
          agencies: "Agencies",
          particulars: "Particulars",
          services: "Services",
          promotions: "Promotions",
          about: "About",
          aboutUs: "About Us",
          contact: "Contact",
          tyc: "Term & Conditions",
          copy: "© 2023 Latin Girls Inc. All rights reserved.",
        },
      },
    },
    pt: {
      translation: {
        whereAreYou: "Onde você está?",
        search: "Pesquisar",
        clear: "Limpar",
        escortsInLima: "Acompanhantes em Lima",
        english: "Inglês",
        portuguese: "Português",
        spanish: "Espanhol",
        advertiseFree: "Poste um anúncio",
        seeMore: "Ver mais",
        endOfList: "Fim da lista",
        homeh1: "Prostitutas e Kinesiólogas no Peru",
        homeh2:
          "Kinesiologistas Acompanhantes em Lima Peru e estrangeiras, chamadas de vídeo de kines A1 putas kines",
        welcomeText:
          "Bem-vindo ao Latin Girls, guia de acompanhantes em Lima, Peru. Nosso objetivo, como sempre, é garantir uma experiência de usuário de alta qualidade onde todo o conteúdo é trabalhado em detalhes e assim satisfazer os paladares mais requintados.",
        AR: "Argentino",
        BO: "Boliviana",
        BR: "Brasileira",
        CL: "Chilena",
        CO: "Colombiana",
        CR: "Costarricense",
        CU: "Cubana",
        DO: "Dominicana",
        EC: "Equatoriana",
        MX: "Mexicana",
        PA: "Panamenha",
        PY: "Paraguaia",
        PE: "Peruana",
        PR: "Porto-riquenha",
        UY: "Uruguaia",
        VE: "Venezuelana",
        verified: "Verificada",
        footer: {
          solutions: "Soluções",
          indepenendent: "Independentes",
          agencies: "Agências",
          particulars: "Particulares",
          services: "Serviços",
          promotions: "Promoções",
          about: "Acerca de",
          aboutUs: "Sobre nós",
          contact: "Contato",
          tyc: "Termos e Condições",
          copy: "© 2023 Latin Girls Inc. Todos os direitos reservados.",
        },
      },
    },
  },
  lng: localStorage.getItem("lang") || "es",
  fallbackLng: "es",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
